import type { Game, PrevTournaments, NextTournaments, TournamentData, ExtendWinners, TournamentsPrizes } from "@/types";

const useAppTournaments = () => {
	useGetGameFiltersData();
	const { t } = useT();
	const { open } = useAppModals();
	const { select } = useGamesState();
	const { handleOpenGame } = useOpenGame(open);
	const { data: appInitData } = useAppInitData();
	const { data: tournamentData } = useGetTournamentData();
	const { handleToggleToFavoriteClick } = useAddGameToFavorite({
		t,
		toastTheme: "dark",
		toastPosition: "bottom-center"
	});
	const { winners, getLeaders, subscribedStatus, roundsPlayed, rank, points } = useTournamentData();

	const randomGame = ref<Game[]>([]);
	const randomIndex = ref(0);
	const showAllGames = ref(false);
	const tabs = [
		{
			title: t("Full schedule"),
			value: "next"
		},
		{
			title: t("Finished"),
			value: "prev"
		}
	];

	const entriesSum = computed(() => getEntries(tournamentData.value?.data?.prizes || []));
	const coinsSum = computed(() => getCoins(tournamentData.value?.data?.prizes || []));
	const tournamentsGames = computed(() => select(tournamentData.value?.data?.gamesIds ?? []));
	const displayedGames = computed(() =>
		showAllGames.value ? tournamentsGames.value : tournamentsGames.value?.slice(0, 9)
	);

	const tournamentActive = computed(() => tournamentData.value?.data?.isActive);
	const tournamentStart = computed(() => tournamentData.value?.data?.start || "");
	const tournamentEnd = computed(() => tournamentData.value?.data?.end || "");

	const { durationLeft, reset } = useCountdown({
		timestamp: tournamentActive.value ? tournamentEnd.value : tournamentStart.value,
		formatToken: "DD[ D ]HH[ H ]mm[ M ]"
	});

	const leaders = computed(() => getLeaders(winners.value as ExtendWinners));

	const handleShowGamesClick = () => {
		showAllGames.value = true;
	};

	const handlePlayClick = (game: Game) => {
		if (!game.isOnlyForApp) {
			handleOpenGame(game.slug);
		}
	};

	const handleRandomGameClick = () => {
		if (!tournamentData.value?.data?.gamesIds) {
			return;
		}

		randomIndex.value = Math.floor(Math.random() * tournamentsGames.value?.length);
		const gameId = tournamentData.value.data.gamesIds[randomIndex.value];

		randomGame.value = select([gameId]);

		handlePlayClick(randomGame.value?.[0]);
	};

	const handleOpenPrizesModal = (title: string, prizes: TournamentsPrizes) => {
		open("LazyOModalTournamentsPrizes", {
			title,
			prizes
		});
	};

	const handleLeaderboardClick = (tournament: PrevTournaments | NextTournaments | TournamentData) => {
		const data = (tournament ?? tournamentData.value.data ?? {}) as TournamentData;
		open("LazyOModalTournamentsLeaderboard", { id: Number(data.historyId || data.history_id) });
	};

	return {
		rank,
		tabs,
		leaders,
		points,
		coinsSum,
		entriesSum,
		appInitData,
		showAllGames,
		displayedGames,
		tournamentsGames,
		tournamentData,
		durationLeft,
		subscribedStatus,
		roundsPlayed,
		tournamentActive,
		reset,
		handleShowGamesClick,
		handlePlayClick,
		handleRandomGameClick,
		handleToggleToFavoriteClick,
		handleOpenPrizesModal,
		handleLeaderboardClick
	};
};
export default useAppTournaments;
